@import "../../../index.scss";

.select-lang {
  $this: &;

  display: flex;
  gap: 5px;

  &__item {
    padding: 8px 12px;
    border: 1px solid #e8eaeb;
    border-radius: 8px;
    color: #8e8e93;
    font-weight: 700;
    cursor: pointer;

    &.active {
      background: $dark-theme-secondary-green;
      border: 1px solid #4f9d3a;
      color: #4f9d3a;
    }
  }

  &_dark-theme {
    #{$this}__item {
      color: #fff;
      border-color: $dark-theme-alpha-white;

      &.active {
        border-color: #4f9d3a;
        background-color: $dark-theme-secondary-green;
        color: #4f9d3a;
      }
    }
  }
}
