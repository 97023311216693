.progress-bar {
  &__wrapper {
    position: relative;
    margin: 0 auto;
    max-width: 960px;
    width: 100%;
  }

  &__background-line {
    height: 4px;
    border-radius: 2px;
    background-color: #EAFAE6;
  }

  &__progress-line {
    position: relative;
    height: 4px;
    border-radius: 2px;
    background-color: #72BF44;
    transition: all .7s ease;

    svg {
      position: absolute;
      right: -10px;
      top: -30px;
      z-index: 10;
      fill: #0fbf4a;
      filter: contrast(74%);

      path {
        fill: #4f9d3a;
      }
    }
  }
}
