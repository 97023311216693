.accordion {
  $this: &;

  padding: 0 10px;

  &__head {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0;
  }

  &__icon {
    display: flex;
    align-items: center;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 13px;
  }

  &__arrow {
    width: 10px;
  }

  &__body {
    height: 0;
    transition: all 1s ease;
    overflow: hidden;

    &.a-open {
      height: auto;
    }
  }

  &_dark-theme {
    #{$this}__title {
      color: #fff;
    }

    svg {
      path {
        fill: #fff;
      }
    }
  }
}
