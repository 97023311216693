.aside-step-status {
  &__el {
    margin-bottom: 20px;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 20px;
    align-items: center;
    justify-content: space-between;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 0 12px;
  }

  &__count-step {
    box-sizing: border-box;
    min-width: 34px;
    min-height: 34px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &_finished {
      background-color: #72BF44;
      border: none;
    }

    &_in-progress {
      border: 2px solid rgba(114, 191, 68, 1);
    }

    &_not-begin {
      border: 2px solid rgba(69, 69, 69, 1);
    }
  }
}
