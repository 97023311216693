@import "../../../media";

.select-search {
  box-sizing: border-box;
  border-radius: 14px;
  padding: 0 30px 0 15px;
  min-width: 100px;
  position: relative;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  height: 48px;
  display: flex;
  align-items: center;

  &__label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    display: grid;
    grid-template-columns: 1fr 40px;
    align-items: center;
    width: 100%;

    input {
      color: gray;
      font-size: 0.875rem;
    }

    span {
      color: gray;
    }

    svg {
      width: 12px;
      margin-left: 8px;
      stroke: gray;
      cursor: pointer;
    }

    .delete {
      display: block;
    }
  }

  &__interface {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 0;
    border-radius: 20px;
    background: transparent;
    right: 0;
    top: 0;
    height: 100%;
  }

  input {
    border: none;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 10px 0px 10px 0px;
    border-radius: 12px;
    font-size: 0.875rem;
  }

  &__title {
    color: rgba(255, 255, 255, 0.87) !important;
    margin-bottom: 4px;
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
  }


  &__wrapper {
    color: #8e8e93;
  }

  &.shadow {
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.05);
  }

  &.border {
    border: 1px solid $color-gray-dark;

    &:focus {
      border-color: $color-navy;
    }

    &:disabled {
      color: $color-gray;
    }
  }

  &.disabled {
    background-color: rgba(0, 0, 0, 0.2);
    border: none;

    & > * {
      color: $color-gray;
    }

    .select__interface {
      display: none;
    }

    &:hover {
      cursor: no-drop;
    }
  }

  &__label {
    display: block;
    line-height: initial;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;


    svg {
      width: 12px;
      margin-left: 8px;
      stroke: #b7bbbd;
      cursor: pointer;

      path {
        stroke: #b7bbbd;
      }
    }


    input {
      color: #fff;
      background: transparent;
    }

    span {
      color: gray;
    }

    svg {
      width: 12px;
      margin-left: 8px;
      stroke: gray;
      cursor: pointer;
    }

    // &:hover {
    .delete {
      display: block;
    }

    // }

    // &:hover {
    .delete {
      display: block;
    }

    // }
  }

  &__interface {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px 0 0;
    border-radius: 20px;
    background: transparent;
    right: 0;
    top: 0;
    height: 100%;
  }

  .delete {
    width: 8px;
    fill: $color-red;
    padding: 5px;
    background: #fddee3;
    margin: -5px 0 -5px;
    border-radius: 3px;
    // display: none;
  }

  .dropdown {
    display: none;
    position: absolute;
    background: rgba(3, 3, 3, 1);
    border-radius: 5px;
    padding: 0;
    width: 100%;
    min-width: 200px;
    top: 52px;
    right: 0;
    overflow-y: auto;
    z-index: 2;

    &__loading {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 1px 19px;
      cursor: pointer;
      //border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      background: #202020;

      svg {
        fill: #72BF44;
        width: 30px;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      padding: 11px 19px;
      cursor: pointer;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      background: #202020;
      //background: #282828;

      .delete {
        display: block;
      }

      &.active {
        background: #414141;
      }

      &:hover {
        background: #2B2B2B;
      }

      &:last-child {
        border: none;
      }
    }
  }
}

.light-theme {
  .select-search {
    background: #F5F6F7;

    &__label {
      color: #000000;


      input {
        color: #000000;
      }
    }

    &__title {
      color: #000000;
    }

    &__interface {
      background: transparent;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: $color-white-bg;
      border: none;
      color: rgba(255, 255, 255, 0.2);

      .select__label {
        color: #8E8E93;
      }
    }
  }

  .select-search .dropdown__item {
    background: #F5F6F7;
    color: #000000;

    &:hover {
      background: #E5E5E5;
    }
  }

  .select-search .dropdown__item.active {
    background: $color-white-bg;
  }
}

