@import '../../../_variables.scss';

.profile-details {
  padding: 20px;
  border-radius: 20px;
  background-color: $dark-theme-dark-grey;
  color: #fff;
  position: relative;

}

.light-theme {
  .base-bg-container {
    background-color: $color-white;
    color: $color-black;
  }

  .profile-details {
    background-color: $color-white;
    color: $color-black;
  }
}

.notary-activity {
  position: absolute;
  right: 20px;
  top: 20px;
}
