@import '../../../variables';

.sub-title {
  color: #000 !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4em;
  margin-bottom: 16px;

  &_xl {
    font-size: 16px;
  }

  &_light {
    color: #000;
  }

  &__label {
    font-size: 14px;
    display: block;
    font-weight: 400;
    line-height: initial;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
    margin-bottom: 0;
    margin-left: 10px;
  }

  &_title {
    @media (max-width: 585px) {
      padding: 0 20px;
    }
  }
}

.dark-theme {
  .sub-title {
    color: rgba(255, 255, 255, 0.87) !important;
  }
}
