@import "../../../../index.scss";

.mortgage-info-card {
  $this: &;

  &:not(:nth-last-child(1)) {
    margin-bottom: 10px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 11px 20px;
    border-radius: 4px 4px 0 0;
    background: rgba(79, 157, 58, 0.1);
  }

  &__title {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 20px;
    color: #4f9d3a;
  }

  &__container {
    padding: 20px;
    background-color: #fff;
  }

  &_dark-theme {
    #{$this}__header {
      background-color: $dark-theme-secondary-green;
    }

    #{$this}__title {
      color: $dark-theme-primary-green;
    }

    #{$this}__container {
      background-color: $dark-theme-alpha-white;
    }
  }
}
