@import "../../../index.scss";

.modal-root {
  z-index: 50;
}
.ui-modal {
  $this: &;

  background: #f5f6f7;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  //overflow: hidden;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
  z-index: 200;
  margin: auto;

  &__wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    overflow: auto;
  }

  &__container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }

  &__close {
    display: inline-block;
    font-size: 20px;
    cursor: pointer;
  }

  &__header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    gap: 10px;
    border-radius: 10px;
  }

  &__body {
    background: #fff;
    padding: 20px;
  }

  &__footer {
    background: #f5f6f7;
    padding: 20px;
    border-radius: 10px;
    display: grid;
    gap: 15px 10px;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 460px) {
      grid-template-columns: 1fr;
    }
  }

  &_dark-theme {
    background-color: $dark-theme-black;

    #{$this}__header {
      background-color: $dark-theme-alpha-white;

      h4 {
        color: #fff;
      }
    }

    #{$this}__body {
      background-color: $dark-theme-alpha-white;
    }

    #{$this}__footer {
      background-color: $dark-theme-alpha-white;
    }

    #{$this}__close {
      color: #fff;
    }
  }
}

.reject-modal .ui-modal {
  max-width: 350px;
}
