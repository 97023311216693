.ui-pagination {
  display: flex;
  align-items: center;
  gap: 3px;

  &__arrow {
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    cursor: pointer;

    svg {
      width: 10px;
    }

    &.left {
      transform: rotate(180deg);
    }
  }

  &__item {
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #8E8E93;
    cursor: pointer;

    &::selection {
      background: transparent;
    }

    &.active {
      color: #232B38;
      background: #FFFFFF;
    }
  }
}
