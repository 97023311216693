@import "../../../../index.scss";

.final-data {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
    border-radius: 16px;
    background-color: #3a3a3a;
    margin-top: 12px;
  }

  &__conditions {
    display: flex;
    align-items: center;
    gap: 0 20px;
  }

  &__separator {
    width: 1px;
    height: 37px;
    background-color: #8e8e93;
  }
}

.light-theme {
  .final-data {
    &__wrapper {
      background-color: #ffffff;
    }
  }
}
