.ui-breadcrumbs {
  $this: &;

  display: flex;
  align-items: center;
  white-space: nowrap;

  div {
    cursor: pointer;
  }

  svg {
    width: 16px;
    margin-right: 10px;
  }

  &_dark-theme {
    span {
      color: #fff;
    }
  }
}
