@import "../../../index.scss";

.textarea {
  resize: none;
  width: 472px;
  height: 172px;
  border-radius: 14px;
  background-color: #f5f6f7;
  padding: 12px 20px;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.15px;
  color: #17191a;

  &::placeholder {
    font-weight: 400;
    color: #8e8e93;
  }

  &_dark-theme {
    background-color: $dark-theme-alpha-black;
    color: #fff;
    border: none;
  }
}
