.comission-form {
  &__warnings {
    display: flex;
    flex-direction: column;
    gap: 16px 0;
    margin-bottom: 16px;
  }

  &__paid {
    display: flex;
    align-items: center;
    gap: 0 16px;
  }
}
