.warning-message {
  &__container {
    display: grid;
    gap: 0 10px;
    grid-template-columns: 23px 1fr;
  }

}

.error-path {
  path {
    fill: #f00d0da1;
  }
}

.warn-tooltip {
  color: #222;
  font-size: 8px;
}
