@import "../../../index.scss";

.search {
  height: 56px;
  display: flex;
  align-items: center;
  width: 100%;
  background: #ffffff;

  input {
    width: 100%;
    padding: 0 40px 0 0;
    height: 100%;
    background: transparent;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    &::placeholder {
      color: #b5b5b5;
    }
  }

  svg {
    width: 19px;
    padding-left: 24px;
    padding-right: 19px;
  }

  &_dark-theme {
    background: none;
    border: 1px solid $dark-theme-alpha-white;

    input {
      border: none;
      color: #fff;
    }
  }
}
