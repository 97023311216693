@import "../../../variables";

.text-input {
  $this: &;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 100%;

    @media (max-width: 585px) {
      width: 100%;
    }
  }

  &__label {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
    letter-spacing: -0.15px;
    color: #8e8e93;
  }

  &__input {
    border: none;
    display: inline-block;
    margin-top: 4px;
    height: 48px;
    border-radius: 14px;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 15px 20px;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 700;
    letter-spacing: -0.15px;
    color: #fff;

    &::placeholder {
      color: #8e8e93;
      font-weight: 400;
    }

    &:focus {
      outline: 1px solid rgba(114, 191, 68, 1);
    }
  }

  &_dark-theme {
    #{$this}__input {
      background-color: $dark-theme-alpha-black;
      border: none;
      color: #fff;

      &::placeholder {
        color: $dark-theme-light-grey;
      }

      &:focus {
        outline-color: $dark-theme-primary-green;
      }
    }

    #{$this}__label {
      color: $dark-theme-light-grey;
    }
  }
}

.light-theme {
  .text-input {
    &__input {
      color: #000000;
      background: $color-white-bg;

      &::placeholder {
        color: #8e8e93;
      }
    }
  }
}

.Mui-focused {
  outline: none !important;
}

.MuiInputBase-root {
  display: inline-block;
  margin-top: 4px;
  height: 48px;
  border-radius: 14px !important;
  background-color: rgba(0, 0, 0, 0.2);
  outline: none !important;

  &:focus {
    outline: 1px solid rgba(114, 191, 68, 1) !important;
  }

  svg {
    fill: #666;
  }

  input {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 700;
    letter-spacing: -0.15px;
    color: #fff;
    outline: none;

    &::placeholder {
      color: #8e8e93;
      font-weight: 400;
    }
  }

}
