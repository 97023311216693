.darkmode-toggle {
  label {
    width: 66px;
    height: 32px;
    position: relative;
    display: block;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 31px;
    cursor: pointer;
    transition: 0.3s;

    &:after {
      content: "";
      width: 28px;
      height: 28px;
      position: absolute;
      top: 2px;
      left: 2px;
      background: #4F9D3A;
      border-radius: 27px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
      transition: 0.3s;
    }

    svg {
      position: absolute;
      width: 20px;
      top: 6px;
      z-index: 100;

      &.sun {
        left: 6px;
        fill: #fff;
        transition: 0.3s;
      }

      &.moon {
        left: 41px;
        fill: #B7BBBD;
        transition: 0.3s;
      }
    }
  }

  #darkmode-toggle {
    width: 0;
    height: 0;
    margin: 0;
    position: absolute;
    visibility: hidden;

    &:checked + label {
      background: #454545;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
      border-radius: 27px;

      &:after {
        left: 64px;
        transform: translateX(-100%);
        background: #4F9D3A;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
      }

      svg {
        &.sun {
          fill: #B7BBBD;
        }

        &.moon {
          fill: #fff;
        }
      }

      + .background {
        background: #242424;
      }
    }

    &:active:after {
      width: 50px;
    }
  }
}
