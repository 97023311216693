@import "../../../index";


.borrowing-conditions {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 2px;

    border-radius: 16px;
    overflow: hidden;

    @media (max-width: 585px) {
      border-radius: 0;
    }
  }

  &__header {
    padding: 16px 20px;
    background-color: #282828;
    display: flex;
    align-items: center;

    &_new {
      background-color: rgba(114, 191, 68, 0.5);
    }

    &_old {
      background-color: rgba(251, 85, 85, 0.5);
    }
  }

  &__row {
    display: flex;
    gap: 0 2px;
  }
}

.light-theme {
    .borrowing-conditions {
      &__header {
        background-color: #fff;
      }
    }
}
