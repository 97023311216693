.sidebar-footer {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__phone-container {
    display: flex;
    align-items: center;
    gap: 0 6px;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 0 8px;
  }

  &__icon-wrapper {
    display: inline-flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    background-color: #282828;
    border-radius: 50%;

    cursor: pointer;
    transition: background-color .3s ease;

    path {
      fill: #72BF44;
    }

    &:hover {
      background-color: #2D7D2F;
      cursor: pointer;
      box-shadow: 2px 3px 10px rgba(87, 198, 0, 0.2);
    }
  }
}

.light-theme {
  .sidebar-footer__icon-wrapper {
    background-color: #E8EAEB;

    &:hover {
      background-color: rgba(87, 198, 0, 0.3);
      cursor: pointer;
      box-shadow: 2px 3px 10px rgba(87, 198, 0, 0.2);

    }
  }
}
