.btn-icon {
  background: none;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .svg-left {
    margin: 0;
    width: 20px;
    height: 20px;

    svg {
      height: 20px;
    }
  }

  &.button-fill:hover {
    background: #2D7D2F;
  }
}

.light-theme {
  .btn-icon {
    .svg-left > svg > rect {
        fill: #E8EAEB;
      }
    }
  }
