.ui-limit {
  display: flex;
  align-items: center;

  &__title {
    font-size: 14px;
    color: #8E8E93;
    margin-right: 16px;
  }

  &__box {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  &__item {
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #8E8E93;
    cursor: pointer;

    &.active {
      color: #232B38;
      background: #FFFFFF;
    }
  }
}
