.info-plate {
  $this: &;

  width: 230px;
  display: inline-flex;
  flex-direction: column;

  &__key {
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 400;
    color: #868686;
    letter-spacing: -0.15px;
  }

  &__value {
    margin-top: 4px;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 400;
    letter-spacing: -0.15px;
    color: #000;
    padding: 10px 0;
  }

  &_dark-theme {
    #{$this}__key {
      color: rgba(255, 255, 255, 0.5);
    }

    #{$this}__value {
      color: #fff;
    }
  }
}
