@import "../../../../index.scss";

.radio-tabs {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.15px;
  //border-radius: 16px;
  color: #fff;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  padding: 20px;
  gap: 10px;
  transition: all 0.3s ease;
  background: $dark-theme-dark-grey;

  &:hover {
    background: $dark-theme-secondary-green;
    color: #fff;
  }

  &__radio {
    width: 15px;
    height: 15px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 1px solid #8E8E93;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      background: #4f9d3a;
      display: none;
      width: 9px;
      height: 9px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
  }

  &.active {
    font-weight: 700;
    border-color: #4f9d3a;

    .radio-tabs__radio {
      border: 1px solid #4f9d3a;

      span {
        display: inline-block;
      }
    }
  }

  &_dark-theme {
    background: $dark-theme-alpha-black;
    color: $dark-theme-light-grey;
    border-color: $dark-theme-alpha-white;

    &.active {
      color: $dark-theme-primary-green;
      background-color: $dark-theme-secondary-green;
      border-color: $dark-theme-primary-green;
    }
  }
}

.light-theme {
  .radio-tabs {
    color: #1a1a1a;
    border-color: $dark-theme-alpha-black;
    background-color: $color-gray-light;

    &.active {
      color: $dark-theme-primary-green;
      background-color: $dark-theme-secondary-green;
      border-color: $dark-theme-primary-green;
    }
  }
}
