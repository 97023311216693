@import '../../../../_media';

.btn-outline {
  padding: 18px 0;
  display: flex;
  align-items: center;
  max-height: 54px;
  justify-content: center;
  background: none;
  border-radius: 20px;
  border: 1px solid rgba(69, 69, 69, 1);
  color: #72BF44;
  font-size: 14px;
  font-weight: 700;
  max-width: 300px;
  width: 100%;

  &.danger {
    border-color: lighten($color-red, 10%);

    &:hover {
      color: lighten($color-red, 10%);
      background-color: rgb(252 59 59 / 10%);
    }

    &__label {
      color: lighten($color-red, 10%);

      &:hover {
        background-color: transparent;
      }

      &-container {
        border: none;

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  @include respond-to(extra-small-screen) {
    max-width: 100%;
  }
  @include respond-to(small-screen) {
    max-width: 100%
  }

  transition: background .3s ease;

  &:hover {
    background: rgba(114, 191, 68, 0.1);
  }

  .disabled {
    color: #3b6f1c;
    background-color: transparent !important;
  }

  &:disabled {
    color: #3b6f1c;
    background-color: transparent !important;
  }
}
.btn-outline--v2 {
  //padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border-radius: 20px;
  //border: 1px solid rgba(69, 69, 69, 1);
  border: 1px solid #4F9D3A;
  color: #72BF44;
  font-size: 14px;
  font-weight: 700;

  transition: background .3s ease;

  &:hover {
    background: rgba(114, 191, 68, 0.1);
  }
}

.light-theme {
  .btn-outline {
    border-color: #4F9D3A;
    color: #4F9D3A;

    & .btn-loading {
      svg {
        width: 40px;
        fill: #4F9D3A;
      }
    }


    &.danger {
      border-color: lighten($color-red, 10%);

      &:hover {
        color: lighten($color-red, 10%);
        background-color: rgb(252 59 59 / 10%);
      }

      &__label {
        color: lighten($color-red, 10%);

        &:hover {
          background-color: transparent;
        }

        &-container {
          border: none;

          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.btn-success {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border-radius: 20px;
  border: 1px solid #4F9D3A;
  color: #4F9D3A;
  font-size: 14px;
  font-weight: 700;
  transition: background .3s ease;

  &:hover {
    background: rgba(114, 191, 68, 0.1);
  }

  &--green {
    color: #fff;
    background-color: #4F9D3A;padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid #4F9D3A;
    font-size: 14px;
    font-weight: 700;
    transition: background .3s ease;


    &:hover {
      background: rgba(114, 191, 68, 0.1);
      color: #4F9D3A;
    }
  }
}
