@import "../../../index.scss";

.filter-tabs {
  $this: &;

  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    white-space: nowrap;
    cursor: pointer;

    background: #ffffff;
    border: 1px solid $dark-theme-white;
    border-radius: 16px;

    &.active {
      background: $dark-theme-secondary-green;
      border: 1px solid #4f9d3a;
      color: #4f9d3a;
    }
  }

  &_dark-theme {
    #{$this}__item {
      background: none;
      border-color: $dark-theme-alpha-white;
      color: #fff;

      &.active {
        border-color: $dark-theme-primary-green;
        color: $dark-theme-primary-green;
        background-color: $dark-theme-secondary-green;
        font-weight: 700;
      }
    }
  }

  .light-theme {

  }
}

.profile-tabs {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  gap: 3px;
  border-radius: 16px;
  overflow: hidden;
  height: fit-content;
}

