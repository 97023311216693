.mortgage-info {
  &__list {
    display: flex;
    gap: 22px 30px;
    margin: 0;
    padding: 0;
    border-radius: 0 0 4px 4px;
    list-style: none;
    flex-wrap: wrap;
  }
}
