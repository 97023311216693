@import "../../../../index.scss";

.underline-tabs {
  $this: &;

  &__link {
    display: inline-flex;
    padding: 16px 20px;
    border-bottom: 3px solid #e8eaeb;
    cursor: pointer;

    &.active {
      border-color: #2d7d2f;
      font-weight: 600;
      color: #231f20;
    }
  }

  &__title {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgba(35, 31, 32, 0.5);
    letter-spacing: -0.15px;
    font-weight: 400;
  }

  &_dark-theme {
    background-color: $dark-theme-black;
    border-color: $dark-theme-light-grey;

    #{$this}__title {
      color: rgba(255, 255, 255, 0.5);
    }

    &.active {
      border-color: $dark-theme-primary-green;
      #{$this}__title {
        font-weight: 600;
        color: $dark-theme-primary-green;
      }
    }
  }
}
