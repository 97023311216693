@import "../../../variables";

.toastify-card {
  position: relative;
  padding: 15px 0 5px;
  background-color: #fff;
  border-radius: 8px;
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 5px 0;
  box-shadow: $box-shadow-card;
  z-index: 99999999999;

  &__header {
    padding: 0 15px;
    z-index: 99999999999;
  }

  &__body {
    padding: 0 15px 10px;
    z-index: 99999999999;
  }

  &__close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    text-align: center;
    z-index: 99999999999;
  }

  &__line {
    height: 3px;
    border-radius: 8px;
    z-index: 99999999999;

    &.error {
      background-color: #cb1414;}
    &.success {
      background-color: #4F9D3A;}
    &.warn {
      background-color: #e5a800;
    }
  }
}
