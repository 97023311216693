.leaflet-div-icon {
  background: transparent;
  border: none;
}

.leaflet-container {
  height: 500px;
  border-radius: 6px;
}

.my-div-icon {
  height: -webkit-fill-available;
  max-height: 100%;
  background: #fff;
  border: 4px solid #0fbf4a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;;
  line-height: 24px;
  color: #191919;
}

.logo-marker-icon {
  $this: &;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 15px;

  svg {
    &:hover {
      #{$this}__path {
        &_first {
          fill: #4FB84E;
        }

        &_second {
          fill: #055532;
        }
      }
    }
  }
}

.leaflet-popup-content-wrapper {
  background-color: #fff;
  width: 232px;
  padding: 16px;
  border-radius: 12px;
}

.leaflet-popup-tip-container {
  path {
    fill: #fff;
  }
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__name {
    font-size: 16px;
    font-weight: 700;
    color: #000;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__info {
    text-align: justify;
    font-size: 16px;
    color: #5C5C5C;
  }
}
