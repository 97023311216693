@import "../../../index";

.mortgage-header {
  &__wrapper {
    width: 100%;
    background-color: #282828;
    margin: 0 auto;
    padding-top: 25px;
  }

  &__list {
    margin: 0 auto;
    max-width: 960px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      display: none;
    }
  }

  &__card {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 0 8px;

  }
}

.light-theme {
  .mortgage-header__wrapper {
    //background-color: $color-white-bg;
    background-color: #fff;
  }
  .mortgage-header__card > svg > rect {
    fill: #E8EAEB;
  }
}

.mortgage-header__card > svg > rect {
  p {
    font-size: 12px;
  }
}
