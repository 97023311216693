@import "../../../index";

.modal-link {
  &__link {
    color: rgba(114, 191, 68, 1);
    text-decoration: underline;
    cursor: pointer;
  }
}

.light-theme {
  .modal-link {
    &__link {
      color: $color-green;
    }
  }
}
