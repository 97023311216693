.btn-link {
  width: fit-content;
  padding: 0;
  background: none;
  color: #72BF44;
  font-size: 14px;
  font-weight: 700;

  &:hover {
    color: #2D7D2F;
  }
}

.light-theme {
  .btn-link.disabled {
    background: transparent;
    color: #2D7D2F;
  }
  .btn-link .btn-loading {
    svg {
      width: 40px;
      fill: #4F9D3A;
    }
  }
}
