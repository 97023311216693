@import "../../../../variables";

.success-reset-pass {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background-color: #fff;
  padding: 20px;
  border-radius: 16px;
}

.dark-theme {
  .success-reset-pass {
    color: #fff;
    background-color: $dark-theme-dark-grey;
  }
}
