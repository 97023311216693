.pass-input {
  &__container {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__btn {
    padding: 0;
    position: absolute;
    right: 20px;
    background: none;
  }
}
