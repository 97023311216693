.sidebar-home {

  .steps-sidebar {
    padding-top: 30px;
    ul {
      padding-left: 0;
    }
    &__nav {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  @media (max-width: 991px) {
    display: none;
  }
}
