@import '../../../_media';

.payment {
 /* & > * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'FF Good Pro', sans-serif;
  }*/

  .container {
    padding: 0 15px;
    margin: 0 auto;
    max-width: 1290px;
    @include respond-to(extra-small-screen) {
      max-width: 500px;
    }
    @include respond-to(small-screen) {
      max-width: 760px;
    }
    @include respond-to(medium-screen) {
      max-width: 990px;
    }
    @include respond-to(large-screen) {
      max-width: 1024px;
    }
    @include respond-to(laptop) {
      max-width: 1200px;
    }
  }

  input[type='text'],
  input[type='password'],
  select,
  textarea {
    width: 100% /* Full width */
  ;

    background: #ccc;
    border: 1px solid #ccc /* Gray border */
  ;
    border-radius: 4px /* Rounded borders */
  ;
    box-sizing: border-box /* Make sure that padding and width stays in place */
  ;
    margin-top: 6px /* Add a top margin */
  ;
    margin-bottom: 32px /* Bottom margin */
  ;
    resize: vertical
    /* Allow the user to vertically resize the textarea (not horizontally) */
  ;
    border-radius: 10px;
    color: black;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 20px;
    padding: 12px 20px;
    font-weight: 500;

    &:focus {
      outline: none;
      border: 1px solid black;
    }

    &::placeholder {
      text-transform: none;
      font-size: 16px;
      font-weight: 500;
    }
  }

  label {
    margin-bottom: 5px;
    display: block;
    font-size: 16px;
    color: #fff;
    position: relative;
  }

  .headerPayments {
    position: fixed;
    width: 100%;
    padding: 20px 0px;
    background-color: #ffffff5e;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__logo {
      & img {
        width: 200px;
      }
    }

    &__phone {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 200px;

      & img {
        width: 36px;
        background-color: #72bf44;
        padding: 10px;
        border-radius: 10px;
      }

      & a {
        text-decoration: none;
        font-size: 36px;
        color: black;
        font-weight: 500;
      }

      & p {
        font-size: 14px;
      }
    }
  }

  .mainPayments {
    display: block;
    height: 100% !important;

    &__header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include respond-to(extra-small-screen) {
        grid-template-columns: 1fr;
      }

      & h3 {
        font-size: 22px;
        font-weight: 500;
      }
    }

    &__orderNumber {
      margin-bottom: 40px;
    }

    &__sessionTime {
      margin-top: 20px;
      margin-bottom: 40px;
      text-align: right;

      @include respond-to(extra-small-screen) {
        text-align: left;
      }
    }

    &__acceptableCards {
      display: flex;
      flex-direction: column;
      text-align: right;

      & h5 {
        margin-bottom: 10px;
        color: #a7a9ab;
        font-weight: 500;
      }
    }

    &__acceptableCardsLogos {
      display: flex;
      align-items: center;
      align-self: flex-end;

      & img {
        height: 30px;
      }

      & > div {
        margin-right: 20px;
        text-align: center;
        font-size: 11px;
        display: flex;
        flex-direction: column;
        align-items: center;

        & p {
          margin-top: 5px;
        }

        &:nth-of-type(1) {
          align-self: flex-start;

          & img {
            height: 25px;
            margin-top: 5px;
          }
        }
      }
    }

    &__content {
      display: flex;
      align-items: center;
      @include respond-to(extra-small-screen) {
        flex-direction: column;
      }

      & > div {
        width: 100%;
      }
    }

    &__formContent {
      box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.1);
      border-radius: 27px;
      padding: 50px;

      @include respond-to(extra-small-screen) {
        padding: 10px;
      }
      @include respond-to(small-screen) {
        padding: 20px;
      }
    }

    &__expiration {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      margin-top: 10px;
      margin-bottom: 30px;

      & > div {
        &:nth-of-type(2) {
          font-size: 24px;
          color: #fff;
        }

        & select {
          margin: 0px;
          cursor: pointer;
          font-weight: 500;
          width: 100px;
          background: #ccc;
        }
      }
    }

    &__cvc {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      gap: 10px;

      & input {
        margin: 0px;
        width: 100px;
        margin-right: 20px;
        background: #ccc;
      }

      & p {
        color: #ccc;
        //color: #fff;
        font-size: 16px;
        max-width: 300px;
      }
    }

    &__cards {
      & > div {
        display: flex;
        justify-content: space-between;
      }

      & img {
        width: 500px;
        @include respond-to(extra-small-screen) {
          max-width: 200px;
          height: 100%;
        }
      }
    }

    &__cardsText {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 65px;
      padding-left: 20px;
      color: #a7a9ab;

      & > div {
        position: relative;

        & > p {
          margin-bottom: 10px;
        }

        &:nth-of-type(2) {
          & img {
            bottom: 10px;
          }
        }

        &:nth-of-type(3) {
          & img {
            bottom: 10px;
          }
        }
      }

      & svg {
        width: 70px;
        position: absolute;
        left: 100px;
        bottom: 20px;
      }
    }

    &__secondCardText {
      position: relative;
      top: -70px;
      @include respond-to(extra-small-screen) {
        display: none;
      }

      & svg {
        width: 320px;
      }

      &-cvc {
        position: absolute;
        right: -395px;
        bottom: 20px;
      }
    }

    &__agreement {
      margin-top: 30px;
      margin-bottom: 30px;

      & div {
        color: #fff;

        & > span {
          & > span {
            color: #3d803d;
            font-weight: 600;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }

    &__pay {
      display: flex;
      align-items: center;
      margin-top: 20px;

      & button {
        border-radius: 12px;
        border: none;
        background-color: #3d803d;
        color: white;
        margin-right: 20px;
        width: 150px;
        height: 50px;
        cursor: pointer;
        font-size: 16px;
      }

      & p {
        font-size: 14px;
      }
    }
  }

  .regular-checkbox {
    -webkit-appearance: none;
    background-color: #fafafa;
    height: 20px;
    border: 1px solid #cacece;
    padding: 9px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: 10px;
    border-radius: 6px;
    transition: 0.3s;
  }

  .footer {
    background-color: #3d803d;
    color: white;
    padding: 40px 0;
    text-align: center;
  }

  // .regular-checkbox:active, .regular-checkbox:checked:active
  //     border-radius: 20px

  .regular-checkbox:checked {
    background-color: #3d803d;
    color: #ffffff;
  }

  .regular-checkbox:checked:after {
    content: '\2714';
    font-size: 12px;
    position: absolute;
    top: 0px;
    left: 5px;
    color: #ffffff;
  }
}

.light-theme {
  label,
  .payment .mainPayments__agreement div {
    color: #000;
  }
  .payment .mainPayments__expiration > div:nth-of-type(2) {
    font-size: 24px;
    color: #000;
  }
  .payment .mainPayments__cvc p {
    color: rgba(0,0,0,0.87);
  }
}
