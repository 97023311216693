@import "../../../index.scss";


.checkbox {
  &__wrapper {
    display: flex;
    align-items: center;
    gap: 0 15px;
    cursor: pointer;
  }

  &__label {
    font-size: 14px;
    color: #fff;
  }

  &__input {
    display: none;
  }

  &__checkmark {
    box-sizing: border-box;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 18px;
    min-width: 18px;
    border: 2px solid rgba(183, 187, 189, 1);
    border-radius: 4px;
  }

  &__input:checked ~ &__checkmark {
    border: none;
    background-color: #72BF44;
  }

  &__checkmark:after {
    content: '✔';
    display: none;
  }

  &__input:checked ~ &__checkmark:after {
    display: inline-block;
  }

  &__checkmark:after {
  }
}

.light-theme {
  .checkbox {
    &__label {
      color: $color-black;
    }
  }
}
