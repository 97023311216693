.text {
  font-size: 14px;
  line-height: 18px;
  color: #B7BBBD;

  &_xl {
    font-size: 16px;
  }
}

.light-theme {
  .text {
    color: #000000;
  }
}
