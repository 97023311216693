@import "../../../variables";

.profile-menu {
  position: relative;

  &__img {
    width: 35px;
    height: 35px;
    border: 1px solid $color-gray-dark;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__list {
    text-align: center;
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin: 0;
    position: absolute;
    padding: 0;
    right: calc(34px - 100%);
    top: 44px;
    overflow: hidden;
    width: max-content;
    display: flex;
    flex-direction: column;
  }

  &__item {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 1px solid #e3e3e3;
    color: #000;

    &:hover {
      background: #e3e3e3;

      &.exit {
        background: $color-lighten-red;
      }
    }
  }
}

.light-theme {
  .profile-menu__img {
    border-color: #B7B6B7
  }
}
