@import "../../../index.scss";

.radio-input {
  $this: &;

  &__label {
    flex: 1;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.125rem;
    letter-spacing: -0.15px;
    color: #17191a;
    display: flex;
    gap: 0 8px;
    cursor: pointer;
  }

  &__input {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    appearance: none;
    background: none;
    border: 1px solid #8e8e93;
    border-radius: 50%;
    transform: translateY(1.5px);

    &::before {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      transform: scale(0);
      background-color: $dark-theme-primary-green;
      transition: 120ms transform ease-in-out;
    }

    &:checked {
      border-color: $dark-theme-primary-green;
      &::before {
        transform: scale(1);
      }
    }
  }

  &_dark-theme {
    color: #fff;

    &__input {
      &::before {
        background-color: #72BF44;
      }

      &:checked {
        border-color: #72BF44;
      }
    }
  }
}

.dark-theme {
  .radio-input__input {
    &::before {
      background-color: #72BF44;
    }

    &:checked {
      border-color: #72BF44;
    }
  }
}
