.toggle-btn {
  position: relative;
  label {
    width: 62px;
    height: 28px;
    position: relative;
    display: block;
    background: #575757;
    border-radius: 31px;
    cursor: pointer;
    transition: 0.3s;

    &:after {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      top: 2px;
      left: 2px;
      background: #282828;
      border-radius: 27px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
      transition: 0.3s;
    }

    div {
      position: absolute;
      width: 16px;
      top: 6px;
      z-index: 100;

      &.sun {
        left: 6px;
        fill: #fff;
        transition: 0.3s;
      }

      &.moon {
        left: 37px;
        fill: #B7BBBD;
        transition: 0.3s;
      }
    }
  }

  &__loading {
    svg {
      width: 40px;
      fill: white;
      position: absolute;
      z-index: 999999;
      top: -6px;
      left: -6px;
    }

    &.checked {
      svg {
        top: -6px;
        left: initial;
        right: -6px;
      }
    }
  }

  #toggle-btn {
    width: 0;
    height: 0;
    margin: 0;
    position: absolute;
    visibility: hidden;

    &:checked + label {
      background: #575757;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
      border-radius: 27px;

      &:after {
        left: 60px;
        transform: translateX(-100%);
        background: #4F9D3A;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
      }

      div {
        &.sun {
          fill: #B7BBBD;
        }

        &.moon {
          fill: #fff;
        }
      }

      + .background {
        background: #575757;
      }
    }

    &:active:after {
      width: 46px;
    }
  }
}

.light-theme {
  .toggle-btn label {
    background: #c2c2c2;
  }

  #toggle-btn:checked + label {
    background: #c2c2c2;
    &:after {
      background-color: #43a529;
    }
  }
}
