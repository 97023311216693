
.documents-amount {
  $this: &;

  display: flex;
  align-items: center;

  &__badge {
    flex: 1;
  }

  &__amount {
    font-size: 1.875rem;
    line-height: 1.125rem;
    font-weight: 700;
    color: #4f9d3a;
    margin-bottom: 11px;
  }

  &__title {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: -0.35px;
    color: #17191a;
  }

  &__dark-theme {
    #{$this}__title {
      color: #fff;
    }
  }
}
