.status-plate {
  width: 181px;
  height: 42px;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;

  &__title {
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 700;

    &_on-confirm {
      color: #687bbf;
    }

    &_completed {
      color: #4f9d3a;
    }

    &_reject {
      color: #fb5555;
    }

    &_not-full {
      color: #ff8a00;
    }
  }
}

.status {
  &_on-confirm {
    background-color: rgba(104, 123, 191, 0.1);
    border-color: #687bbf;
  }

  &_completed {
    background-color: rgba(79, 157, 58, 0.1);
    border-color: #4f9d3a;
  }

  &_reject {
    background-color: rgba(251, 85, 85, 0.1);
    border-color: #fb5555;
  }

  &_not-full {
    background-color: rgba(255, 138, 0, 0.1);
    border-color: #ff8a00;
  }
}
