@import "../../../index.scss";

.header {
  $this: &;


  & > * {
    flex: 0 0 auto;
  }

  position: relative;
  display: flex;
  gap: 15px;
  justify-content: space-between;
  padding: 21px 0;

  z-index: 10;

  &_dark-theme {
    background-color: $dark-theme-dark-grey;
  }

  &_light-theme {
    background-color: #ffffff;
  }

  &__wrapper {
    position: relative;
    z-index: 3;
    width: 100%;
  }


  &__step {
    display: none;

    @media (max-width: 992px) {
      display: block;
    }
  }

  &__left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 45px;
  }

  &__logo {
    @media (max-width: 992px) {
      display: none;
    }
  }

  &__burger {
    @media (min-width: 992px) {
      display: none;
    }
  }

  &__burger-wrapper {
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;

    width: 100%;
    height: 0;

    opacity: 0;
    background: $dark-theme-dark-grey;
    z-index: 20;

    transition: all .4s ease-in-out;
  }

  &__burger-content {
    padding: 20px;
    background: #282828;
  }

  &__burger-wrapper.burger-open {
    top: 75px;
    opacity: 1;
  }

  .burger-open &__burger-overlay {
    position: absolute;

    width: 100%;
    height: 100%;
    min-height: 100vh;

    background: rgba(0, 0, 0, 0.5);
  }

  &__burger-wrapper.burger-open .header__burger-content {
    height: 150px;

    & > * {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 992px) {
    .darkmode-toggle,
    .select-lang {
      display: none;
    }
  }
}



.light-theme {
  .header__wrapper {
    background: #fff;
  }

  .header__burger-content {
    background: #E5E5E5;
  }
}
