@import "../../../../../index.scss";

.signature-card {
  box-sizing: border-box;
  width: 350px;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  background-color: #fff;

  &__btn-loading {
    padding: 0;
    margin: 0;
  }

  &__btn-loading > svg {
    fill: #4F9D3A;
    width: 40px;
    padding: 0;
    margin: 0;
  }

  &__date {
    font-size: 0.75rem;
    line-height: 0.875rem;
    font-weight: 400;
    color: #8e8e93;
  }

  &__download-btn {
    background: none;
  }

  &_dark-theme {
    background-color: $dark-theme-alpha-white;

    h5 {
      color: #fff;
    }
  }
}
