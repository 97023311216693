@import "src/media";

.layout {
  $this: &;

  &__wrapper {
    display: grid;
    grid-template-columns: 255px 1fr;
  }

  &__main {
    background: #f5f6f7;
    padding: 30px 28px 50px;
    z-index: 1;
    overflow: hidden;

    &_dark-theme {
      background-color: $dark-theme-black;

      * > .page__title {
        color: #ffffff;
      }
    }
  }
}

.page {
  min-height: 100vh;
  height: max(100vh, 100%);

  &__navigation {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    & > div {
      display: flex;
      justify-content: center;

      &:first-child {
        justify-content: flex-start;
      }

      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  &__navigation-v2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    & > div {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 767px) {
    position: relative;
    overflow-x: hidden;
  }
}
