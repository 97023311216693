.ui-tabs {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__navigation {
    display: flex;
    gap: 8px;
    @media (max-width: 585px) {
      //padding: 0 15px;
    }
  }

  &.dark-theme {
    * {
      color: #ffffff;
      background: none;
    }
  }

  &__link {
    display: inline-flex;
    padding: 16px 20px;
    border-bottom: 3px solid #e8eaeb;
    cursor: pointer;

    &.active {
      border-color: #2d7d2f;
      font-weight: 600;
      color: #231f20;
    }
  }

  &__title {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgba(35, 31, 32, 0.5);
    letter-spacing: -0.15px;
    font-weight: 400;
  }
}
