@import "../../../../index.scss";

.mortgage-filter-tabs {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 270px;
  width: 100%;
  //height: 42px;
  font-size: 0.875rem;
  line-height: 1.125rem;
  letter-spacing: -0.15px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  border-radius: 16px;
  color: #fff;
  font-weight: 700;
  //gap: 10px;
  cursor: pointer;
  padding: 14px;
  box-sizing: border-box;
  text-align: center;

  transition: all 0.3s ease;

  &:hover {
    background: $dark-theme-secondary-green-hover;
    color: #4F9D3A;
  }

  &.active {
    color: #4f9d3a;
    font-weight: 700;
    border-color: #4f9d3a;
    background: $dark-theme-secondary-green;
  }

  &_dark-theme {
    background: none;
    color: $dark-theme-light-grey;
    border-color: $dark-theme-alpha-white;

    &.active {
      color: $dark-theme-primary-green;
      background-color: $dark-theme-secondary-green;
      border-color: $dark-theme-primary-green;
    }
  }
}

.light-theme {
  .mortgage-filter-tabs {
    color: #1a1a1a;
    border-color: $dark-theme-alpha-black;

    &.active {
      color: $dark-theme-primary-green;
      background-color: $dark-theme-secondary-green;
      border-color: $dark-theme-primary-green;
    }
  }
}
