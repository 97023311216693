.warning-wrapper {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 15px 0;
  position: fixed;
  right: 15px;
  top: 15px;
  bottom: auto;
  z-index: 99999999;
  width: 350px
}
