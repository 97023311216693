@import '../../../_variables.scss';

.base-bg-container {
  padding: 20px;
  border-radius: 20px;
  background-color: $dark-theme-dark-grey;

  &_light {
    background-color: $dark-theme-light-grey;
  }

  @media (max-width: 585px) {
    border-radius: 0;
  }
}

.light-theme {
  .base-bg-container {
    background-color: $color-white;
  }
}
