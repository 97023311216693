.notary-checking {
  &__row {
    display: flex;
    gap: 0 16px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 16px 0;
  }

  &__card {
    flex: 1;
  }

  &__loading {
    align-items: center;
    gap: 0 8px;
    display: grid;
    grid-template-columns: 40px 1fr;
  }

  &__spinner {
    animation: lds-ring 1.2s linear infinite;
  }

  &__status {
    display: flex;
    align-items: center;
    gap: 0 16px;
    padding: 0 15px;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
