@import '../../../index.scss';

.app-list {
  padding: 20px;
  border-radius: 20px;
  background-color: $dark-theme-dark-grey;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: green;
      width: 80px;
    }
  }

  &__item {
    padding: 0 0 17px;
    border-bottom: 1px solid #4c4c4c;
    display: grid;
    gap: 10px;
    grid-template-areas:
        "id name phone name2 phone2 status"
        "dkp dkp dkp dkp dkp dkp";
    grid-template-columns: 40px 120px 120px 120px 120px 1fr;

    &:last-child {
      border: none;
    }

    div {
      .gray {
        margin-bottom: 5px;
      }
    }

    div {
      &:nth-child(1) {
        grid-area: id;
      }

      &:nth-child(2) {
        grid-area: name;
      }

      &:nth-child(3) {
        grid-area: phone;
      }

      &:nth-child(4) {
        grid-area: name2;
      }

      &:nth-child(5) {
        grid-area: phone2;
      }

      &:nth-child(6) {
        grid-area: status;
      }

      &:nth-child(7) {
        grid-area: dkp;
      }
    }

    @media (max-width: 980px) {
      grid-template-areas:
        "id status"
        "name  phone"
        "name2  phone2"
        "dkp  dkp";
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      gap: 15px;

      div {
        &:nth-child(1) {
          grid-area: id;
        }

        &:nth-child(2) {
          grid-area: name;
        }

        &:nth-child(3) {
          grid-area: phone;
        }

        &:nth-child(4) {
          grid-area: name2;
        }

        &:nth-child(5) {
          grid-area: phone2;
        }

        &:nth-child(6) {
          grid-area: status;
        }

        &:nth-child(7) {
          grid-area: dkp;
        }
      }
    }

    &:hover {
      color: #4F9D3A;
    }
  }
}

.light-theme {
  .app-list {
    background-color: $color-white;
    color: $color-black;
  }
}
