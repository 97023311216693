@import '../../../index';

.otp-input {
  &__container {
    display: flex;
    gap: 0 8px;
  }

  &__input {
    padding: 0 0 0 20px;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 60px;
    border: 1px solid #454545;
    border-radius: 16px;
    background: rgba(0, 0, 0, 0.2);

    caret-color: #fff;
    color: rgba(255, 255, 255, 0.87);

    &:focus {
      border-color: #4F9D3A;
    }
  }
}

.light-theme {
  .otp-input {
    &__input {
      color: rgba(0, 0, 0, 0.87);
      caret-color: $dark-theme-middle-grey;
      background: #F5F6F7;
    }
  }
}
