.seller-status {
  &__wrapper {
    display: flex;
    gap: 0 16px;
    //align-items: center;
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 8px 0;
  }
}
