@import "../../../index.scss";

.steps-sidebar {
  $this: &;

  display: flex;
  flex-direction: column;
  gap: 32px 0;

  &__nav {
    padding-bottom: 32px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  @media (max-width: 992px) {
    display: none;
  }
}
