.cabinet-document-block {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px 0;
  }

  &__document-card {
    background-color: #282828;
    border-radius: 14px;
    padding: 20px;
    display: flex;
    align-items: center;

    @media (max-width: 585px) {
      border-radius: 0;
    }
    &:hover {
      background-color: lighten(#282828, 10%);
    }
  }
}

.light-theme {
  .cabinet-document-block__document-card {
    background: #fff;
    &:hover {
      background-color: darken(#fff, 10%);
    }
  }
}


.not-selected {
  path {
    fill: #989898;
  }
}
