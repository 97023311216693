@import "../../../variables";

.base-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__selector {
    display: flex;
    gap: 16px;
    color: #ffffff;
    margin-bottom: 24px;
    border-bottom: 2px solid #333333;
  }

  &__item {
    padding: 10px;
    width: 100%;
    font-size: 16px;
    text-align: center;
    cursor: pointer;

    &.active {
      border-bottom: 2px solid #4f9d3a;
    }
  }

  &__title {
    color: $color-white;
    text-align: center;
  }

  a, h4, h2, h1, h5 {
    color: $color-white;
  }

  &__container {
    background-color: $dark-theme-middle-grey;
    padding: 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px 0;
    width: 100%;
  }
}

.light-theme {
  .base-form {
    &__container {
      background-color: $color-white;
    }

    &__title {
      color: $color-black;
    }

    &__item {
      color: $color-black;
    }

    &__selector {
      border-bottom: 2px solid #dadada;
    }

    a, h4, h2, h1, h5 {
      color: $color-black;
    }
  }
}
