@import "./variants/link";
@import './variants/outline';
@import "./variants/icon";
@import "./variants/forward";

.btn {
  $this: &;

  transition: all 0.3s ease;
  overflow: hidden;

  &-dark-theme {
    background: none;
    color: #ffffff;

    &:hover {
      color: #4F9D3A;
    }
  }

  &-table-btn {
    display: flex;
    align-items: center;
    text-align: center;

    padding: 18px 36px;
    background-color: #fff;
    height: 100%;
    border-radius: 0;
    position: relative;

    &:before {
      content: "";
      height: 70%;
      background: #b7c3d4;
      width: 1px;
      position: absolute;
      left: 0;
    }

    span {
      font-style: normal;
      font-size: 14px;
      margin-top: 3px;
      color: #4f9d3a;
    }

    .svg-left {
      height: 15px;
      width: 13px;
      margin-right: 14px;
    }

    &:hover {
      background-color: #e3ecde;
    }

    &_dark-theme {
      background: none;
    }
  }

  &-loading {
    svg {
      width: 40px;
      fill: white;
    }
  }
}

.light-theme {
  .btn-loading {
    svg {
      width: 40px;
      fill: #E8EAEB;
    }
  }
}
