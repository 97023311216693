@import "../../../index.scss";

.table {
  $this: &;

  overflow: hidden;
  text-align: left;

  &__clear {
    color: $color-red;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
  }

  &__wrapper {
    border-radius: 12px;
    border: 1px solid #e2e8f0;
    overflow: hidden;
    width: 100%;
    max-width: 1600px;
  }

  &__td {
    white-space: inherit;
    min-width: 150px;

    &:hover {
      .table__buttons {
        display: flex;

        svg {
          padding: 0 6px;
          width: 10px;
          cursor: pointer;
        }
      }
    }
  }

  &__head {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-flow: column;
    gap: 20px;

    font-weight: 600;
    font-size: 11px;
    color: #4f9d3a;
    padding: 17.5px 16px;
    background: rgba(79, 157, 58, 0.05);
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-flow: column;
    gap: 20px;

    padding: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #1a202c;

    &:nth-child(odd) {
      background: rgba(133, 133, 133, 0.05);
    }
  }

  &__empty {
    text-align: center;
    font-size: 16px;
    padding: 15px 0;
  }

  &__loading {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 35px 0;
  }

  &__buttons {
    display: none;

    svg {
      padding: 8px;
      height: 19px;
      cursor: pointer;
      //fill: #8E8E93 !important;

      &:hover {
        //fill: #4f9d3a !important;
      }
    }
  }

  &__sort {
    display: flex;
    margin-left: 10px;
    align-items: center;
    width: 40px;

    svg {
      height: 10px;
      cursor: pointer;

      &:last-child {
        padding: 5px 5px 5px 0px;
        box-sizing: content-box;
      }

      &:first-child {
        padding: 5px 0px 5px 5px;
        box-sizing: content-box;
      }

      &:hover {
        path {
          stroke: lighten(#4f9d3a, 20%);
        }
      }
    }
  }

  &_dark-theme {
    border-color: $dark-theme-alpha-white;

    #{$this}__head {
      background-color: $dark-theme-secondary-green;
    }

    #{$this}__td {
      color: #fff;
    }

    #{$this}__empty {
      color: #ffffff;
    }

    #{$this}__loading {
      color: #ffffff;
    }
  }
}

.scroll-wrapper {
  overflow-x: auto;
  padding-bottom: 3px;
}

.scroll-inner {
  width: max-content;
}

.b-line {
  border-bottom: 1px solid #e2e8f0;
}

.btn-table-button {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  background: none;
}
.action-button {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  background: #5b9bd5;
  color: #ffffff;
}

.table-loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 8px solid;
  border-color: #4FB84E transparent;
  animation: l1 1s infinite;
}
@keyframes l1 {to{transform: rotate(.5turn)}}
