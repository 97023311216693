@import "../../../index.scss";

.list-count {
  $this: &;

  span {
    color: #8e8e93;
  }

  &_dark-theme {
    & {
      span {
        color: $dark-theme-light-grey;
      }

      b {
        color: #fff;
      }
    }
  }
}
