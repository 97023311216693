.mortgage-info-form {
  display: flex;
  flex-direction: column;
  gap: 2px 0;

  border-radius: 16px;

  &__row {
    display: flex;
    gap: 0 16px;

    @media (max-width: 585px) {
      flex-direction: column;
      gap: 16px;
    }
  }

  &__select {
    flex: 1;
  }

  &__wrapper {
    //margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px 0;
  }
}
