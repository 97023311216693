@import "../../../../index.scss";

.loan-pledge-data {
  &__container {
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
  }

  &__plate {
    width: 171px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #687bbf;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #fff;
    font-weight: 700;
    border-radius: 16px;
  }

  &_dark-theme {
    background-color: $dark-theme-alpha-white;
  }
}
