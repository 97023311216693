@import "../../../../index";

.condition-block {
  &__wrapper {
    padding: 16px;
    background-color: #282828;
    display: flex;
    align-items: center;
    gap: 0 8px;
    flex: 1;
  }
}

.light-theme {
  .condition-block__wrapper {
    background: $color-white;

    & > svg > rect {
      fill: #E8EAEB;
    }
  }
}
